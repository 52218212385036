import React from 'react';
import Layout from '../components/layout/Layout';

const Resume = () => {
  return (
    <Layout>
      <div className='py-4 px-6 m-4 md:m-24'>
        <div className='text-4xl font-bold text-center md:text-left'>Resume</div>
        <div>
          <div className='flex flex-col md:flex-row w-full mt-10'>
            <div className='w-full md:w-1/2 text-2xl font-bold mb-4 md:mb-0'>
              Work<br />Experience
            </div>
            <div className='w-full md:w-1/2'>
              <div className='flex flex-col gap-6'>
              <div className='flex gap-2'>
                  <div className='w-[30%]'>Mar 2024 - Jun 2024</div>
                  <div className='w-[70%]'>
                    <div className='text-xl font-bold'>React JS, Firebase, Tailwind CSS </div>
                    <div className='mt-'>
                      <span className='font-semibold'>E-Bhart Ecommerce Platform User | Admin </span>
                      <ul class="list-disc ">
                        <li className='mt-2'>Developed a fully functional e-store using React.js, Firebase, and Tailwind CSS.</li>
                        <li className='mt-2'>Implemented user authentication and authorization using Firebase Authentication.</li>
                        <li className='mt-2'>Integrated Firebase Firestore for real-time data storage and retrieval.</li>
                        <li className='mt-2'>Designed and implemented responsive and visually appealing UI using Tailwind CSS.</li>
                        <li className='mt-2'>Utilized React Router for seamless navigation and a smooth user experience.</li>
                        <li className='mt-2'>Applied Context API, Redux, and implemented payment integration.</li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div className='flex gap-2'>
                  <div className='w-[30%]'>Jan 2024 - Apr 2024</div>
                  <div className='w-[70%]'>
                    <div className='text-xl font-bold'>Freelance </div>
                    <div className='mt-'>
                      <span className='font-semibold'>Frontend and Backend Developer | Daman Game <br />RV Infotech Jaipur, Rajasthan </span>
                      <ul class="list-disc ">
                        <li className='mt-2'>Maintained and enhanced both frontend and backend systems.</li>
                        <li className='mt-2'>Developed APIs in Node.js and managed MySQL database.</li>
                        <li className='mt-2'>Collaborated with the web development team for seamless integration.</li>
                        <li className='mt-2'>Conducted backups and implemented disaster recovery procedures.</li>
                        <li className='mt-2'>Monitored system health and performance to ensure optimal operation.</li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div className='flex gap-2'>
                  <div className='w-[30%]'>Sep 2023 - Dec 2023</div>
                  <div className='w-[70%]'>
                    <div className='text-xl font-bold'>React Js Developer </div>
                    <div className='mt-'>
                      <span className='font-semibold'>Booking-Travel-Solution Web Application <br />Gomaxoo Private LTD Indore, MP </span>
                      <ul class="list-disc ">
                        <li className='mt-2'>Implemented the front end using React.js for a responsive and interactive user interface.
                        </li>
                        <li className='mt-2'>Leveraged MUI for component styling, ensuring a consistent and modern look.
                        </li>
                        <li className='mt-2'>Utilized React Query for efficient data fetching and state management.</li>
                        <li className='mt-2'>Applied Tailwind CSS for efficient and customizable design.</li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div className='flex gap-2'>
                  <div className='w-[30%]'>Oct 2022 - Apr 2023</div>
                  <div className='w-[70%]'>
                    <div className='text-xl font-bold'>Intern</div>
                    <div className='mt-'>
                      <span className='font-semibold'>Python Developer / Database Architecture DigiChum Infotech</span>
                      <ul class="list-disc ">
                        <li className='mt-2'>Built a high-performance employee management system backend with Django, Python, and MySQL.</li>
                        <li className='mt-2'>Designed and managed the database schema for efficient data management.
                        </li>
                        <li className='mt-2'>user interface using Bootstrap,  resulting in a seamless user experience
                        </li>
                        <li className='mt-2'>Optimized site performance with efficient database queries and caching.</li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className='w-full h-[0.5px] bg-black mt-12'></div>
          <div className='flex flex-col md:flex-row w-full mt-10'>
            <div className='w-full md:w-1/2 text-2xl font-bold mb-4 md:mb-0'>
              Education
            </div>
            <div className='w-full md:w-1/2'>
              <div className='flex flex-col gap-6'>
                <div className='flex gap-2'>
                  <div className='w-[30%]'>2021 - 2024</div>
                  <div className='w-[70%]'>
                    <div className='text-xl font-bold'>BSc - Computer Science | 7.6 CGP</div>
                    <div className='mt-4'>
                      Collage - Sanskar College of Professional Studies Indore MP
                    </div>
                  </div>
                </div>
                <div className='flex gap-2'>
                  <div className='w-[30%]'>2020 - 2021</div>
                  <div className='w-[70%]'>
                    <div className='text-xl font-bold'>12th | 91%</div>
                    <div className='mt-4'>
                      School - Sharda Excellence School, Shujalpur, MP
                    </div>
                  </div>
                </div>
                <div className='flex gap-2'>
                  <div className='w-[30%]'>2019 - 2020</div>
                  <div className='w-[70%]'>
                    <div className='text-xl font-bold'>10th | 89%</div>
                    <div className='mt-4'>
                      School - Sharda Excellence School, Shujalpur, MP
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className='w-full h-[0.5px] bg-black mt-12'></div>
          <div className='flex flex-col md:flex-row w-full mt-10'>
            <div className='w-full md:w-1/2 text-2xl font-bold mb-4 md:mb-0'>
              Skills<br />& Expertise
            </div>
            <div className='w-full md:w-1/2'>
              <div className='flex flex-col gap-6'>
                <div className='flex gap-2'>
                  <div className='w-[30%]'></div>
                  <div className='w-[70%]'>
                    {/* <div className='text-xl font-bold'>Editor</div> */}
                    <div className='mt-4'>
                      <ul class="list-decimal ">
                        <li className='mt-4'>Programming Lanagauge - <b>JavaScript</b></li>
                        <li className='mt-4'>Frontend Technologies - <b>HTML, HTML5, CSS, CSS3, React JS, Tailwind CSS</b></li>
                        <li className='mt-4'>Backend Technologies - <b>Node JS</b></li>
                        <li className='mt-4'>Databases  - <b>MySQL</b></li>
                        <li className='mt-4'>Tools & Platforms - <b>VS Code, PyCharm, Ms Excel, Canva</b></li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
}

export default Resume;
