import React from 'react'
import Layout from '../components/layout/Layout'

const Contact = () => {
  return (
    <Layout>
      <div className='py-4 px-6 m-4 md:m-24'>
        <div className='grid gap-12 md:gap-4'>
          <div className='flex flex-col md:flex-row w-full mt-10'>
            <div className='w-full md:w-1/2 mb-4 md:mb-0'>
              <div className='text-2xl font-bold'>Contact</div>
              <div className='py-4 mr-4'>Looking forward to hearing from you</div>
              <div>
                <div className='text-xl font-bold'>Phone</div>
                <div>+91-9685656669</div>
              </div>
              <div className='mt-8'>
                <div className='text-xl font-bold'>Email</div>
                <div>rohitfuleriyya@gmail.com</div>
              </div>
            </div>

            <div className='w-full md:w-1/2'>
              <div className='flex flex-col gap-4'>
                <div className='flex flex-col md:flex-row gap-4'>
                  <div className='w-full'>
                    <label htmlFor="first-name">First Name</label>
                    <input id="first-name" type="text" className='mt-2 border hover:border-2 border-gray-900 p-2 outline-none w-full' />
                  </div>
                  <div className='w-full'>
                    <label htmlFor="last-name">Last Name</label>
                    <input id="last-name" type="text" className='mt-2 border hover:border-2 border-gray-900 p-2 outline-none w-full' />
                  </div>
                </div>

                <div className='flex flex-col md:flex-row gap-4'>
                  <div className='w-full'>
                    <label htmlFor="email">Email</label>
                    <input id="email" type="email" className='mt-2 border hover:border-2 border-gray-900 p-2 outline-none w-full' required />
                  </div>
                  <div className='w-full'>
                    <label htmlFor="subject">Subject</label>
                    <input id="subject" type="text" className='mt-2 border hover:border-2 border-gray-900 p-2 outline-none w-full' />
                  </div>
                </div>

                <div className='flex flex-col gap-4 mt-4'>
                  <div className='w-full'>
                    <label htmlFor="message">Message</label>
                    <textarea id="message" className='mt-2 border hover:border-2 border-gray-900 p-2 outline-none w-full h-32' required />
                  </div>
                  <div className='flex items-center'>
                    <button className='h-[50px] w-full md:w-[150px] rounded-full text-center bg-[#eea302ff] border border-black hover:bg-[#f3f3f3ff]'>Submit</button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default Contact
