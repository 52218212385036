import React, { useState } from 'react'

const Navbar = () => {
    const [isOpen, setIsOpen] = useState(false)

    const toggleMenu = () => {
        setIsOpen(!isOpen)
    }

    return (
        <>
            <nav className='py-4 px-6 mx-4'>
                <div className='flex justify-between items-center'>
                    <a href="/">
                        <div className='flex items-center gap-2'>
                            <div className='w-[30px] h-[30px] bg-[#eea302ff] rounded-full'></div>
                            <div>
                                <h1 className='text-2xl text-black font-bold'>Rohit Ahirwar</h1>
                            </div>
                        </div>
                    </a>
                    <div className='hidden md:flex p-2 gap-4'>
                        <a href="/resume">
                            <div className='cursor-pointer hover:text-yellow-600'>Resume</div>
                        </a>
                        <div className='w-[1.5px] bg-gray-800'></div>
                        <a href="/project">
                            <div className='cursor-pointer hover:text-yellow-600'>Project</div>
                        </a>
                        <div className='w-[1.5px] bg-gray-800'></div>
                        <a href="/contact">
                            <div className='cursor-pointer hover:text-yellow-600'>Contact</div>
                        </a>
                    </div>
                    <div className='md:hidden'>
                        <button onClick={toggleMenu} className='focus:outline-none'>
                            <svg
                                className='w-6 h-6 text-black'
                                fill='none'
                                stroke='currentColor'
                                viewBox='0 0 24 24'
                                xmlns='http://www.w3.org/2000/svg'
                            >
                                <path strokeLinecap='round' strokeLinejoin='round' strokeWidth='2' d='M4 6h16M4 12h16M4 18h16'></path>
                            </svg>
                        </button>
                    </div>
                </div>
                {isOpen && (
                    <div className='flex flex-col p-2 gap-4 mt-4 md:hidden'>
                        <a href="/resume">
                            <div className='cursor-pointer hover:text-yellow-600'>Resume</div>
                        </a>
                        <div className='w-full h-[1.5px] bg-gray-800'></div>
                        <a href="/project">
                            <div className='cursor-pointer hover:text-yellow-600'>Project</div>
                        </a>
                        <div className='w-full h-[1.5px] bg-gray-800'></div>
                        <a href="/contact">
                            <div className='cursor-pointer hover:text-yellow-600'>Contact</div>
                        </a>
                    </div>
                )}
            </nav>
        </>
    )
}

export default Navbar
