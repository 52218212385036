import React from 'react'
import Layout from '../components/layout/Layout'

const Project = () => {
  return (
    <Layout>
      <div className='py-4 px-6 m-4 md:m-24'>
        <div className='text-4xl font-bold text-center md:text-left'>Projects</div>
        <div className='grid gap-12 mad:gap-4'>
          <div className='flex flex-col md:flex-row w-full mt-10'>
            <div className='w-full md:w-1/2   mb-4 md:mb-0'>
              <div className='text-xl font-bold'>E-BHART ECOMMERCE - 1</div>
              <div className='py-4 mr-4'>
                <ul class=" ">
                  <li className='mt-2'>Developed a fully functional e-store using React.js, Firebase, and Tailwind CSS.</li>
                  <li className='mt-2'>Implemented user authentication and authorization using Firebase Authentication.</li>
                  <li className='mt-2'>Integrated Firebase Firestore for real-time data storage and retrieval.</li>
                  <li className='mt-2'>Designed and implemented responsive and visually appealing UI using Tailwind CSS.</li>
                  <li className='mt-2'>Utilized React Router for seamless navigation and a smooth user experience.</li>
                  <li className='mt-2'>Applied Context API, Redux, and implemented payment integration.</li>
                </ul>
              </div>
            </div>

            <div className='w-full md:w-1/2'>
              <div className=''>
                <img className='rounded-lg' src="/images/1.jpg" alt="" />
                <a href="https://ebhart-ecommerce.vercel.app/" target='__blank'>
                  <button class="w-full bg-[#eea302ff] hover:bg-[#eea302e5]   text-white font-bold py-2 px-2 mt-4 rounded">
                    Visit Website
                  </button>
                </a>
              </div>
            </div>
          </div>

          <div className='flex flex-col md:flex-row w-full mt-10 '>
            <div className='w-full md:w-1/2   mb-4 md:mb-0'>
              <div className='text-2xl font-bold'>Bussines Website - 2</div>
              <div className='py-4 mr-4'><ul class=" ">
                  <li className='mt-2'>Developed a fully functional Bussines Website using React.js, Tailwind CSS.</li>
                  <li className='mt-2'>Designed and implemented responsive and visually appealing UI using Tailwind CSS.</li>
                  <li className='mt-2'>Utilized React Router for seamless navigation and a smooth user experience.</li>
                </ul></div>
            </div>

            <div className='w-full md:w-1/2'>
              <div className=''>
                <img src="/images/2.jpg" className='rounded-lg' alt="" />
                <a href="https://bussines-website.vercel.app/" target='__blank'>
                  <button class="w-full bg-[#eea302ff] hover:bg-[#eea302e5]  text-white font-bold py-2 px-2 mt-4 rounded">
                    Visit Website
                  </button>
                </a>
               
              </div>
            </div>
          </div>

          <div className='flex flex-col md:flex-row w-full mt-10'>
            <div className='w-full md:w-1/2   mb-4 md:mb-0'>
              <div className='text-2xl font-bold'>GYM Webaite - 3</div>
              <div className='py-4 mr-4'><ul class=" ">
              <li className='mt-2'>Fully functional Landing Website using HTML Bootstrap,.</li>
                </ul></div>
            </div>

            <div className='w-full md:w-1/2'>
              <div className=''>
                <img className='rounded-lg' src="/images/9.jpg" alt="" />
                <a href="https://gym1-plum.vercel.app/" target='__blank'>
                  <button class="w-full bg-[#eea302ff] hover:bg-[#eea302e5]  text-white font-bold py-2 px-2 mt-4 rounded">
                    Visit Website
                  </button>
                </a>
              </div>
            </div>
          </div>

          <div className='flex flex-col md:flex-row w-full mt-10'>
            <div className='w-full md:w-1/2   mb-4 md:mb-0'>
              <div className='text-2xl font-bold'>TRAVA TRAVEL WEBSITE - 4</div>
              <div className='py-4 mr-4'><ul class=" ">
                  <li className='mt-2'>Developed a fully functional e-store using React.js, Firebase, and Tailwind CSS.</li>
                  <li className='mt-2'>Implemented user authentication and authorization using Firebase Authentication.</li>
                  <li className='mt-2'>Integrated Firebase Firestore for real-time data storage and retrieval.</li>
                  <li className='mt-2'>Designed and implemented responsive and visually appealing UI using Tailwind CSS.</li>
                  <li className='mt-2'>Utilized React Router for seamless navigation and a smooth user experience.</li>
                  <li className='mt-2'>Applied Context API, Redux, and implemented payment integration.</li>
                </ul></div>
            </div>

            <div className='w-full md:w-1/2'>
              <div className=''>
              <img className='rounded-lg' src="/images/4.jpg" alt="" />
                <a href="https://travel-landing-page-website.netlify.app/" target='__blank'>
                  <button class="w-full bg-[#eea302ff] hover:bg-[#eea302e5]  text-white font-bold py-2 px-2 mt-4 rounded">
                    Visit Website
                  </button>
                </a>
              </div>
            </div>
          </div>

          <div className='flex flex-col md:flex-row w-full mt-10'>
            <div className='w-full md:w-1/2   mb-4 md:mb-0'>
              <div className='text-2xl font-bold'>BOOKING TRAVEL WEBSITE - 5</div>
              <div className='py-4 mr-4'>
                <ul class="list-disc ">
                  <li className='mt-2'>Developed a fully functional e-store using React.js, Firebase, and Tailwind CSS.</li>
                  <li className='mt-2'>Implemented user authentication and authorization using Firebase Authentication.</li>
                  <li className='mt-2'>Integrated Firebase Firestore for real-time data storage and retrieval.</li>
                  <li className='mt-2'>Designed and implemented responsive and visually appealing UI using Tailwind CSS.</li>
                  <li className='mt-2'>Utilized React Router for seamless navigation and a smooth user experience.</li>
                  <li className='mt-2'>Applied Context API, Redux, and implemented payment integration.</li>
                </ul>
              </div>
            </div>

            <div className='w-full md:w-1/2'>
              <div className=''>
              <img className='rounded-lg' src="/images/5.jpg" alt="" />
                <a href="https://bookingkaro.netlify.app/" target='__blank'>
                  <button class="w-full bg-[#eea302ff] hover:bg-[#eea302e5]  text-white font-bold py-2 px-2 mt-4 rounded">
                    Visit Website
                  </button>
                </a>
              </div>
            </div>
          </div>

          <div className='flex flex-col md:flex-row w-full mt-10'>
            <div className='w-full md:w-1/2   mb-4 md:mb-0'>
              <div className='text-2xl font-bold'>WEATHER TRANCKING - 6</div>
              <div className='py-4 mr-4'>
                <ul class="list-disc ">
                  <li className='mt-2'>Developed a fully functional e-store using React.js, Firebase, and Tailwind CSS.</li>
                  <li className='mt-2'>Implemented user authentication and authorization using Firebase Authentication.</li>
                  <li className='mt-2'>Integrated Firebase Firestore for real-time data storage and retrieval.</li>
                  <li className='mt-2'>Designed and implemented responsive and visually appealing UI using Tailwind CSS.</li>
                  <li className='mt-2'>Utilized React Router for seamless navigation and a smooth user experience.</li>
                  <li className='mt-2'>Applied Context API, Redux, and implemented payment integration.</li>
                </ul>
              </div>
            </div>

            <div className='w-full md:w-1/2'>
              <div className=''>
              <img className='rounded-lg' src="/images/6.jpg" alt="" />
                <a href="https://bookingkaro.netlify.app/" target='__blank'>
                  <button class="w-full bg-[#eea302ff] hover:bg-[#eea302e5]  text-white font-bold py-2 px-2 mt-4 rounded">
                    Visit Website
                  </button>
                </a>
              </div>
            </div>
          </div>

          <div className='flex flex-col md:flex-row w-full mt-10'>
            <div className='w-full md:w-1/2   mb-4 md:mb-0'>
              <div className='text-2xl font-bold'>SIMPLE QUIZE APPLICATION - 7</div>
              <div className='py-4 mr-4'>
                <ul class="list-disc ">
                  <li className='mt-2'>Developed a fully functional e-store using React.js, Firebase, and Tailwind CSS.</li>
                  <li className='mt-2'>Implemented user authentication and authorization using Firebase Authentication.</li>
                  <li className='mt-2'>Integrated Firebase Firestore for real-time data storage and retrieval.</li>
                  <li className='mt-2'>Designed and implemented responsive and visually appealing UI using Tailwind CSS.</li>
                  <li className='mt-2'>Utilized React Router for seamless navigation and a smooth user experience.</li>
                  <li className='mt-2'>Applied Context API, Redux, and implemented payment integration.</li>
                </ul>
              </div>
            </div>

            <div className='w-full md:w-1/2'>
              <div className=''>
              <img className='rounded-lg' src="/images/7.jpg" alt="" />
                <a href="https://bookingkaro.netlify.app/" target='__blank'>
                  <button class="w-full bg-[#eea302ff] hover:bg-[#eea302e5]  text-white font-bold py-2 px-2 mt-4 rounded">
                    Visit Website
                  </button>
                </a>
              </div>
            </div>
          </div>

          <div className='flex flex-col md:flex-row w-full mt-10'>
            <div className='w-full md:w-1/2   mb-4 md:mb-0'>
              <div className='text-2xl font-bold'>ZOMETO WEB APPLICATION - 8</div>
              <div className='py-4 mr-4'>
                <ul class="list-disc ">
                  <li className='mt-2'>Developed a fully functional e-store using React.js, Firebase, and Tailwind CSS.</li>
                  <li className='mt-2'>Implemented user authentication and authorization using Firebase Authentication.</li>
                  <li className='mt-2'>Integrated Firebase Firestore for real-time data storage and retrieval.</li>
                  <li className='mt-2'>Designed and implemented responsive and visually appealing UI using Tailwind CSS.</li>
                  <li className='mt-2'>Utilized React Router for seamless navigation and a smooth user experience.</li>
                  <li className='mt-2'>Applied Context API, Redux, and implemented payment integration.</li>
                </ul>
              </div>
            </div>

            <div className='w-full md:w-1/2'>
              <div className=''>
              <img className='rounded-lg' src="/images/8.jpg" alt="" />
                <a href="https://zometo-clone.netlify.app/" target='__blank'>
                  <button class="w-full bg-[#eea302ff] hover:bg-[#eea302e5]  text-white font-bold py-2 px-2 mt-4 rounded">
                    Visit Website
                  </button>
                </a>
              </div>
            </div>
          </div>

          <div className='flex flex-col md:flex-row w-full mt-10'>
            <div className='w-full md:w-1/2   mb-4 md:mb-0'>
              <div className='text-2xl font-bold'>Service Webaite - 9</div>
              <div className='py-4 mr-4'><ul class=" ">
              <li className='mt-2'>Developed a fully functional Landing Website using React.js, Tailwind CSS.</li>
                  <li className='mt-2'>Designed and implemented responsive and visually appealing UI using Tailwind CSS.</li>
                  <li className='mt-2'>Utilized React Router for seamless navigation and a smooth user experience.</li>
                </ul></div>
            </div>

            <div className='w-full md:w-1/2'>
              <div className=''>
                <img className='rounded-lg' src="/images/3.jpg" alt="" />
                <a href="https://landingpage-bice-six.vercel.app/" target='__blank'>
                  <button class="w-full bg-[#eea302ff] hover:bg-[#eea302e5]  text-white font-bold py-2 px-2 mt-4 rounded">
                    Visit Website
                  </button>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default Project