import React from 'react'
import Layout from '../components/layout/Layout'

const Home = () => {
  return (
    <Layout>
      <div className='py-4 px-6 flex flex-col items-center md:flex-row md:items-start'>
        <div className='flex flex-col md:flex-row m-4 md:m-28 w-full'>
          <div className='mb-4 w-full md:w-1/2 flex justify-center md:justify-end'>
            <img className='w-60 md:w-80 lg:w-[600px] rounded-full' src="/images/profile.jpg" alt="Profile" />
          </div>
          <div className='ml-0 md:ml-4 w-full md:w-1/2 mt-6 text-center md:text-left'>
            <div className='text-4xl md:text-6xl font-bold mb-4 md:mb-10'>
              Hello
            </div>
            <div className='text-xl md:text-2xl font-bold mb-2 md:mb-4'>
              A Bit About Me
            </div>
            <div className='w-full md:w-[80%] mx-auto md:mx-0'>
              A highly skilled React JS Developer with experience in full-stack development, specializing in front-end and back-end
              technologies. Proficient in JavaScript, HTML, CSS, React.js, Node.js, and MySQL. Adept at developing responsive
              web applications, implementing APIs, and collaborating with development teams to deliver seamless project
              solutions.

            </div>
            <div className='flex flex-row md:flex-row justify-between md:justify-between mt-6  md:space-y-0 cursor-pointer text-sm md:text-2xl font-bold items-center'>
              <a href="/resume" className='flex justify-center'>
                <div className='flex items-center justify-center h-24 w-24 md:h-[150px] md:w-[150px] rounded-full text-center bg-[#eea302ff] border border-black hover:bg-[#f3f3f3ff]'>Resume</div>
              </a>
              <a href="/project" className='flex justify-center'>
                <div className='flex items-center justify-center h-24 w-24 md:h-[150px] md:w-[150px] rounded-full text-center bg-[#ff3b25ff] border border-black hover:bg-[#f3f3f3ff]'>Project</div>
              </a>
              <a href="/contact" className='flex justify-center'>
                <div className='flex items-center justify-center h-24 w-24 md:h-[150px] md:w-[150px] rounded-full text-center bg-[#80d8daff] border border-black hover:bg-[#f3f3f3ff]'>Contact</div>
              </a>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default Home
