import React from 'react';

const Footer = () => {
  return (
    <footer className='py-4 px-6 mx-4 mb-6'>
      <hr />
      <div className='flex flex-col md:flex-row justify-between mt-6'>
        <div className='py-4'>
          <div className='text-xl text-black font-bold'>
            Phone
          </div>
          <div>+91-9685656669</div>
        </div>
        <div className='py-4'>
          <div className='text-xl text-black font-bold'>
            Email
          </div>
          <div>rohitahirwar968565@gmail.com</div>
        </div>
        <div className='py-4'>
          <div className='text-xl text-black font-bold'>
            Follow Me
          </div>
          <div className='flex'>
            <a href='https://www.linkedin.com/in/rohitahirwar1' target='__blank'>
              <img src="/images/linkedin.png" className='w-[30px]' alt="" />
            </a>
            <a href='https://github.com/rohitAhirwar' target='__blank'>
              <img src="/images/github.png" className='w-[30px]' alt="" />
            </a>
          </div>
        </div>
        <div className='py-4'>
          <div>© 2035 By Rohit Ahirwar.</div>
          <div>Powered and secured by Rohit</div>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
